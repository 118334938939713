<template>
  <div
    class="job-invite-button"
    :class="bem('job-invite-button', { fullWidth })"
  >
    <div v-if="invited" class="job-invite-button__label">
      <span class="job-invite-button__icon-label">
        <SvgIcon icon="check" :size="24" />
        <span>Приглашение отправлено</span>
      </span>
    </div>

    <component
      v-else
      :is="blocked ? 'BasicTooltip' : 'div'"
      :content="tooltipTextForBlockedState"
      placement="top"
      :max-width="225"
      theme="dark"
      class="tooltip"
    >
      <BasicButton
        class="job-invite-button__btn"
        :class="bem('job-invite-button__btn', { fullWidth })"
        appearance="primary"
        size="sm"
        @click="openModalAndSendGA"
        :disabled="blocked"
      >
        <span class="job-invite-button__icon-label">
          <SvgIcon icon="meet" :size="24" />
          <span>Пригласить</span>
        </span>
      </BasicButton>
    </component>

    <JobInviteFormModal
      v-if="showJobInviteForm"
      @close="onClose"
      @success="openSuccessModal"
      @access-required="openAccessRequiredModal"
      :job-invite-button="jobInviteButton"
      :invited-from="invitedFrom"
    />

    <LimitModal
      v-else-if="showResumeAccessRequiredModal"
      title="Приглашение недоступно"
      :modal-info="limitModalInfo"
      @close="onClose"
    />

    <DecoratedResultModal
      v-else-if="showSuccessModal"
      @close="onClose"
      appearance="success"
      title="Вы отправили приглашение"
    >
      <template #actions>
        <BasicButton
          appearance="primary"
          :href="`/conversations/${jobInviteButton.userLogin}`"
          size="l"
          @click="sendGaEventAndClose"
        >
          Перейти в диалоги
        </BasicButton>

        <BasicButton appearance="secondary" size="l" @click="onClose">
          Закрыть
        </BasicButton>
      </template>
    </DecoratedResultModal>
  </div>
</template>
<script>
import { JobInvitesService } from 'src/services/job-invites.js';
import { bem } from 'src/utils/bem.js';

import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import BasicTooltip from 'src/components/BasicTooltip/BasicTooltip.vue';
import DecoratedResultModal from 'src/components/DecoratedResultModal/DecoratedResultModal.vue';
import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
import JobInviteFormModal from 'src/components/JobInviteFormModal/JobInviteFormModal.vue';
import LimitModal from 'src/components/LimitModal/LimitModal.vue';

export default {
  name: 'JobInviteButton',
  components: {
    DecoratedResultModal,
    JobInviteFormModal,
    BasicTooltip,
    BasicButton,
    SvgIcon,
    LimitModal,
  },
  inject: ['visitor'],
  props: {
    jobInviteButton: {
      type: Object,
      required: true,
    },
    invitedFrom: {
      type: String,
      required: true,
      validator(value) {
        return ['vacancy', 'resumes_card', 'profile'].includes(value);
      },
    },
    companyId: {
      type: String,
      required: true,
    },
    fullWidth: Boolean,
  },
  emits: ['success'],
  data() {
    return {
      state: null, // formOpened, accessRequired, success
    };
  },
  methods: {
    bem,
    openSuccessModal(response) {
      this.$emit('success', response);
      this.state = 'success';
    },
    openAccessRequiredModal() {
      this.state = 'accessRequired';
    },
    openModalAndSendGA() {
      const eventCategoriesMap = {
        vacancy: 'vacancy_response',
        resumes_card: 'user_card',
        profile: 'profile',
      };

      window.helpers.trackEventByName('invite', {
        event_category: eventCategoriesMap[this.invitedFrom],
      });

      if (this.jobInviteButton.state === 'can_send') {
        this.state = 'formOpened';
        return;
      }

      this.state = 'accessRequired';
      JobInvitesService.trackPlaceholderView(this.visitor?.login);
    },
    sendGaEventAndClose() {
      window.helpers.trackEventByName('invite_messages');

      this.onClose();
    },
    onClose() {
      this.state = null;
    },
  },
  computed: {
    showJobInviteForm() {
      return this.state === 'formOpened';
    },
    showResumeAccessRequiredModal() {
      return this.state === 'accessRequired';
    },
    showSuccessModal() {
      return this.state === 'success';
    },
    blocked() {
      return this.jobInviteButton.state.startsWith('blocked_');
    },
    invited() {
      return this.jobInviteButton.state === 'already_sent';
    },
    links() {
      return {
        packages: `/companies/${this.companyId}/cp/billing/packages/new`,
        resumeAccess: `/companies/${this.companyId}/cp/billing/resume_accesses/new`,
      };
    },
    tooltipTextForBlockedState() {
      if (this.jobInviteButton.state === 'blocked_by_user') {
        return 'Специалист вас заблокировал — вы не можете пригласить его на вакансию';
      }

      if (this.jobInviteButton.state === 'blocked_by_you') {
        return 'Вы заблокировали специалиста и не можете пригласить его на вакансию';
      }

      return null;
    },
    limitModalInfo() {
      return {
        description:
          'Чтобы пригласить специалиста, оплатите доступ к базе резюме.',
        action: {
          title: 'Купить доступ',
          href: this.links.resumeAccess,
          appearance: 'primary',
          type: 'link',
        },
      };
    },
  },
};
</script>
