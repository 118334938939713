<template>
  <ModalWindow
    title="Запрос на консультацию"
    appearance="new"
    @close="$emit('close', $event)"
  >
    <form
      class="consultation-request-form-modal__form"
      method="post"
      action="/"
      @submit.prevent="formAction"
    >
      <InputLabel
        text="Напишите, с чем нужна помощь"
        for-id="consultation_request_message"
      >
        <BasicTextarea
          name="message"
          id="consultation_request_message"
          :max-length="500"
          v-model="message"
          placeholder=""
          :rows="13"
          :max-rows="256"
          :disabled="isLoading"
        />
      </InputLabel>

      <p class="consultation-request-form-modal__tip">
        Запрос увидят только эксперт и администраторы сервиса.
      </p>

      <BasicNotification
        v-if="showErrorNotification"
        appearance="alert-borderless"
        icon="minus-circle"
      >
        {{ errorText }}
      </BasicNotification>

      <ButtonGroup
        class="consultation-request-form-modal__footer"
        appearance="inline"
      >
        <BasicButton
          :disabled="submitIsDisabled || isLoading"
          type="submit"
          appearance="primary"
          size="l"
        >
          Отправить
        </BasicButton>
        <BasicButton
          appearance="secondary"
          size="l"
          @click="$emit('close', $event)"
        >
          Отменить
        </BasicButton>
      </ButtonGroup>
    </form>
  </ModalWindow>
</template>
<script>
import { ExpertsService } from 'src/services/experts.js';
import { captureException } from 'src/utils/sentry.js';

import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import BasicNotification from 'src/components/BasicNotification/BasicNotification.vue';
import ButtonGroup from 'src/components/ButtonGroup/ButtonGroup.vue';
import BasicTextarea from 'src/components/form/BasicTextarea/BasicTextarea.vue';
import InputLabel from 'src/components/form/InputLabel/InputLabel.vue';
import ModalWindow from 'src/components/overlays/ModalWindow/ModalWindow.vue';

export default {
  name: 'ConsultationRequestFormModal',
  components: {
    BasicNotification,
    ModalWindow,
    BasicButton,
    BasicTextarea,
    InputLabel,
    ButtonGroup,
  },
  props: {
    expertLogin: {
      type: String,
      required: true,
    },
  },
  emits: ['success', 'close', 'sendConversationsLimit'],
  data() {
    return {
      message: '',
      showErrorNotification: false,
      errorText: '',
      isLoading: false,
    };
  },
  methods: {
    async formAction() {
      window.helpers.trackEvent('experts', 'send', 'request_button');

      this.showErrorNotification = false;
      this.isLoading = true;

      try {
        const { message, expertLogin } = this;
        await ExpertsService.requestConsultation({
          message,
          expertLogin,
        });
        this.emitSuccessEvent();
      } catch (error) {
        if (error.response?.data?.message === 'Daily limit reached') {
          this.$emit('sendConversationsLimit');
        } else {
          this.showErrorNotification = true;

          this.errorText =
            'При отправке запроса возникла ошибка. Повторите попытку. Если проблема сохранится, попробуйте отправить запрос позже.';
        }
        captureException(error);
      } finally {
        this.isLoading = false;
      }
    },
    emitSuccessEvent() {
      this.$emit('success');
    },
  },
  computed: {
    submitIsDisabled() {
      return this.message.length < 1;
    },
  },
};
</script>
