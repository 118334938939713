<template>
  <span class="inline-list">
    <span
      v-for="(item, index) in items"
      :key="getKey ? getKey(item) : item.key"
    >
      <slot :item="item" :index="index" />
      <template v-if="index !== lastElementIndex">
        <template v-if="separator === 'comma'">{{ ', ' }}</template>
        <template v-else-if="separator === 'space'">{{ ' ' }}</template>
        <template v-else-if="separator === 'ellipsis'">{{ ' ... ' }}</template>
        <InlineSeparator v-else :color="separatorColor" />
      </template>
    </span>
  </span>
</template>

<script>
import InlineSeparator from 'src/components/text/InlineSeparator/InlineSeparator.vue';

export default {
  name: 'InlineList',
  components: { InlineSeparator },
  props: {
    items: {
      type: Array,
      required: true,
    },
    separator: String,
    separatorColor: String,
    getKey: Function,
  },
  computed: {
    lastElementIndex() {
      return this.items.length - 1;
    },
  },
};
</script>
