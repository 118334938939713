<template>
  <PageSection>
    <WithSidebar
      v-if="placeholderStatus !== 'removed'"
      placement="left"
      collapse="top"
    >
      <template #sidebar>
        <UserPageSidebar
          :user="user"
          :placeholder="placeholder"
          @openContacts="$emit('openContacts')"
        />
      </template>
      <template #default>
        <slot />
      </template>
    </WithSidebar>
    <template v-else>
      <slot />
    </template>
  </PageSection>
</template>

<script>
import PageSection from 'src/components/sections/PageSection/PageSection.vue';
import UserPageSidebar from 'src/components/users/UserPageSidebar/UserPageSidebar.vue';
import WithSidebar from 'src/components/WithSidebar/WithSidebar.vue';

export default {
  name: 'UserPageTemplate',
  components: {
    UserPageSidebar,
    WithSidebar,
    PageSection,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    placeholder: {
      type: Object,
    },
  },
  emits: ['openContacts'],
  computed: {
    placeholderStatus() {
      if (!this.placeholder) return '';
      return this.placeholder.status;
    },
  },
};
</script>
