<template>
  <div class="habr-admin-label">
    <SvgIcon icon="admin" size="24" />
    <span>Администратор Хабра</span>
  </div>
</template>

<script>
import SvgIcon from '../../icons/SvgIcon/SvgIcon.vue';

export default {
  name: 'HabrAdminLabel',
  components: { SvgIcon },
};
</script>
